import React from 'react';
import * as actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
import ButtonFooter from '../parts/ButtonFooter';
/* eslint-disable */

export default function DefaultContent() {
    const dispatch = useDispatch();

    return (
        <div className="Content-body">
            <span className="App-symptoms">Do you have <b>any</b> of these symptoms?</span>
            <ul className="Content-list">
                <li>Fever</li>
                <li>New onset of cough</li>
                <li>Worsening of chronic cough</li>
                <li>Shortness of breath</li>
                <li>Difficulty breathing</li>
                <li>Sore throat</li>
                <li>Difficulty swallowing</li>
                <li>Decrease or loss of sense of taste or smell</li>
                <li>Chills</li>
                <li>Unexplained headaches</li>
                <li>Unexplained fatigue, malaise, muscle aches</li>
                <li>Nausea/vomiting, diarrhea, stomach pain</li>
                <li>Eye pain or pink eye (conjunctivitis)</li>
                <li>Runny nose or stuffy nose without known cause</li>
            </ul>
            <div className="Content-footer">
                <ButtonFooter/>
                <div className="Privacy-desc">
                    <a className="Default-link" onClick={() => dispatch(actions.privacyRequest)}>Privacy Notice</a>
                    <span>No personal information of any kind is collected or stored via www.uhnscreen.ca</span>
                </div>
            </div>
        </div>
    );
}