import produce from 'immer';
import * as actions from './actions';
import { screeningStatus } from '../constants';

const initialState = {
    screeningStatus: screeningStatus.NONE,
    privacyRequest: false,
    submitTime: null,
    currentPage: 1,
    // !!!: manually update the maximum page count when you add/remove pages
    maxPage: 4,
    historyLength: 10,
};

export default (state = initialState, action) =>
    produce(state, (draft) => {
        const { type, payload } = action;
        switch (type) {
            case actions.SCREENING_FAILED:
                draft.screeningStatus = screeningStatus.FAIL;
                draft.submitTime = new Date();
                break;
            case actions.SCREENING_PASSED:
                draft.screeningStatus = screeningStatus.PASS;
                draft.submitTime = new Date();
                break;
            case actions.SCREENING_RESET:
                draft.screeningStatus = screeningStatus.NONE;
                draft.privacyRequest = false;
                draft.submitTime = null;
                draft.currentPage = 1;
                draft.isEmployee = null;
                break;
            case actions.PRIVACY_REQUEST:
                draft.privacyRequest = true;
                break;
            case actions.INCREMENT_PAGE:
                draft.currentPage = draft.currentPage + 1;
                const isAtEnd = draft.currentPage > state.maxPage;
                if (isAtEnd) {
                    draft.screeningStatus = screeningStatus.PASS;
                    draft.submitTime = new Date();
                    draft.currentPage = 1;
                }
                break;
            case actions.GO_TO_PAGE:
                draft.currentPage = action.destPage;
                if (draft.currentPage > state.maxPage) {
                    draft.screeningStatus = screeningStatus.PASS;
                    draft.submitTime = new Date();
                    draft.currentPage = 1;
                }
                break;
            case actions.RESET_PAGE:
                draft.currentPage = 1;
                break;
            case actions.SET_HISTORY_LENGTH:
                draft.historyLength = payload.history;
                break;
            default:
                return draft;
        }
    });
