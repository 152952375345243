import React from 'react';
import { connect } from 'react-redux';

import './Timestamp.css';

function Timestamp({ time }) {
    return (
        <div className="Timestamp">
            <span className="Timestamp-date">{time.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
            <span className="Timestamp-time">{time.toLocaleTimeString('default', { hour12: true, hour: '2-digit', minute: '2-digit' })}</span>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        time: state.reducer.submitTime,
    };
}

export default connect(mapStateToProps)(Timestamp);
