import React from 'react';
import { useDispatch } from 'react-redux';
import { matomo } from '../../../matomo'

import { screeningReset } from '../../../redux/actions';
import Timestamp from '../../parts/Timestamp';
import ReturnButton from '../../parts/ReturnButton';

import './Conclusion.css'


export default function PassContent() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        matomo.track({
            url: window.location.origin,
            action_name: 'Screening Passed',
            'e_a': 'Passed',
            'e_c': 'Screened',
        });

        const timer = setTimeout(() => {
            dispatch(screeningReset);
        }, 7200000);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <div className="Conclusion">
            
            <span className="Pass-text">PASS</span>
            
            <Timestamp/>
            <div className="Content-footer-pass">
                <ReturnButton/>
            </div>
        </div>
    );
}