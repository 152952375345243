import React from 'react';
import { connect } from 'react-redux';
import { screeningStatus } from '../../constants';
import DefaultContent from '../content/DefaultContent';
import OccHealthContent from '../content/OccHealthContent';
import ExposureContent from '../content/ExposureContent';
import PassContent from '../content/conclusion/PassContent';
import FailContent from '../content/conclusion/FailContent';
import PrivacyContent from '../content/PrivacyContent';

import logo from '../../images/uhn-logo.png';
import './Container.css';
import FederalTravelGuidelinesContent from '../content/FederalTravelGuidelinesContent';

function ContentContainer({ status, privacy, currentPage }) {
    function content() {
        if (privacy) {
            return <PrivacyContent />;
        } else if (status === screeningStatus.PASS) {
            return <PassContent />;
        } else if (status === screeningStatus.FAIL) {
            return <FailContent />;
        } else if (currentPage === 1) {
            return <DefaultContent />;
        } else if (currentPage === 2) {
            return <OccHealthContent />;
        } else if (currentPage === 3) {
            return <ExposureContent />;
        } else if (currentPage === 4) {
            return <FederalTravelGuidelinesContent />;
        } else {
            return <DefaultContent />;
        }
    }

    return (
        <div className={`Container-${status}`}>
            <div className="Container-main">
                <header className="Container-header">
                    <img src={logo} className="Container-logo" alt="UHN Logo" />
                </header>
                {content()}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        status: state.reducer.screeningStatus,
        privacy: state.reducer.privacyRequest,
        currentPage: state.reducer.currentPage,
    };
}

export default connect(mapStateToProps)(ContentContainer);
