export const SCREENING_PASSED = 'SCREENING_PASSED';
export const screeningPassed = {
    type: SCREENING_PASSED,
};

export const SCREENING_FAILED = 'SCREENING_FAILED';
export const screeningFailed = {
    type: SCREENING_FAILED,
};

export const SCREENING_RESET = 'SCREENING_RESET';
export const screeningReset = {
    type: SCREENING_RESET,
};

export const PRIVACY_REQUEST = 'PRIVACY_REQUEST';
export const privacyRequest = {
    type: PRIVACY_REQUEST,
};

export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const incrementPage = {
    type: INCREMENT_PAGE,
};

export const GO_TO_PAGE = 'GO_TO_PAGE';
export const goToPage = (destPage) => ({ type: GO_TO_PAGE, destPage });

export const RESET_PAGE = 'RESET_PAGE';
export const resetPage = {
    type: RESET_PAGE,
};

export const SET_HISTORY_LENGTH = 'SET_HISTORY_LENGTH';
export const setHistoryLength = (history) => ({
    type: SET_HISTORY_LENGTH,
    payload: {
        history,
    },
});
