import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css';

export default function ExposureContent() {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    I am confirming that, if I have had any <b>exposures</b> to COVID positive individuals in the past <b>10 days</b>, I have reported this to
                    Health Services (Occupational Health) and following instructions provided.
                </span>
            </div>
            <div className="Content-footer">
                <ButtonFooter reverse />
                <StatusDots />
            </div>
        </div>
    );
}
