import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css';

export default function FederalTravelGuidelinesContent() {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    I am confirming that I meet the <b>federal travel guidelines</b> if I have traveled out of Canada in the past <b>14 days</b>.
                </span>
            </div>

            <div className="Content-addendum">
                For details regarding the federal travel guidelines{' '}
                <a target="_new" href="https://travel.gc.ca/travel-covid">
                    click here
                </a>
                .
            </div>

            <div className="Content-footer">
                <ButtonFooter reverse />
                <StatusDots />
            </div>
        </div>
    );
}
