import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'

export default function OccHealthContent() {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    I am confirming that, if I had any COVID symptoms
                    in the last <b>10 days</b> I have reported to
                    UHN Health Services (Occupational Health) and been cleared to
                    return to work.
                </span>
            </div>
            <div className="Content-footer">
                <ButtonFooter reverse/>
                <StatusDots/>
            </div>
        </div>
    );
}