import React from 'react';
import { useSelector } from 'react-redux';

import './StatusDots.css'
/* eslint-disable */

export default function StatusDots() {
    const currentPage = useSelector(state => state.reducer.currentPage);
    const maxPage = useSelector(state => state.reducer.maxPage);
    return (
        <div className="Dots">
            {
                Array.from({length: maxPage}, (_, i) => {
                    const active = i === currentPage - 1;
                    return <span key={i} className={active ? "Selected-dot" : "Unselected-dot"}></span>
                })
            }
        </div>
    );
}