import React from 'react';
import { matomo } from '../../../matomo';

import Timestamp from '../../parts/Timestamp';
import ReturnButton from '../../parts/ReturnButton';

import '../Content.css';
import './Conclusion.css';

export default function FailContent() {
    React.useEffect(() => {
        matomo.track({
            url: window.location.origin,
            action_name: 'Screening Failed',
            e_a: 'Failed',
            e_c: 'Screened',
        });
    });

    return (
        <div className="Conclusion">
            <p className="Fail-text-main">
                <b>FAIL</b>
            </p>
            <p className="Fail-text-small">Based on your responses you have not passed the screening requirements.</p>
            <p className="Fail-text-small">
                You are&nbsp;
                <b>
                    <u>NOT</u>
                </b>
                &nbsp;to enter UHN.
            </p>
            <p className="Fail-text-small">
                If you are a TeamUHN member, you are REQUIRED to notify your manager of your inability to attend work and contact Health Services using the
                following&nbsp;
                <a target="_new" href="http://tinyurl.com/HSCOVIDAssessmentandRTW">
                    COVID-19 Assessment Form
                </a>
                .
            </p>
            <p className="Fail-text-small">
                If you are a learner or contractor, connect with your organization as you are not permitted to enter the premises.
            </p>
            <Timestamp />
            <div className="Content-footer-fail">
                <ReturnButton />
            </div>
        </div>
    );
}
