import React from 'react';
import * as actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
/* eslint-disable */
import './Button.css';

// "Yes", "No" buttons at the bottom of a question
// - parameter reverse: normally a "no" response will fail, but "yes" with reverse will also fail
// - parameter noFail: any response will increment the page
// - parameter action: a function to call with a "yes" or "no"
function ButtonFooter({ reverse, noFail, action, nextPage }) {
    const dispatch = useDispatch();

    function doAction(answer) {
        // run the custom action first, since we might want to change the store
        if (action) {
            action(answer);
        }

        if (noFail || (answer === 'no' && !reverse) || (answer === 'yes' && reverse && nextPage === null)) {
            dispatch(actions.incrementPage);
        } else if (answer === 'yes' && nextPage !== null) {
            dispatch(actions.goToPage(nextPage));
        } else {
            dispatch(actions.screeningFailed);
        }
    }

    return (
        <div className="Button-group">
            <a className="Button" onClick={() => doAction('no')}>
                No
            </a>
            <a className="Button" onClick={() => doAction('yes')}>
                Yes
            </a>
        </div>
    );
}

ButtonFooter.defaultProps = {
    reverse: false,
    noFail: false,
    nextPage: null,
};

export default ButtonFooter;
