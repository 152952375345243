import React from 'react';
import ReturnButton from '../parts/ReturnButton';
import pkg from '../../../package.json';
/* eslint-disable */

export default function DefaultContent() {
    return (
        <div className="App-body">
            <p>
                Ontario Health is requiring hospitals to conduct more in-depth active screening of healthcare workers working in public hospitals.
                We are providing UHN staff with the option to use <a href="http://www.uhnscreen.ca">www.uhnscreen.ca</a> to complete this screening requirement.
                Staff who do not wish to use <a href="http://www.uhnscreen.ca">www.uhnscreen.ca</a> will need to wait in line to be screened upon entry to the hospital.
            </p>
            <p>No personal information of any kind is collected or stored via this process or through <a href="http://www.uhnscreen.ca">www.uhnscreen.ca</a>.</p>
            <p>If you have questions about the collection of your information, please contact your Manager.</p>
            <div className="App-privacy-footer">
                <ReturnButton/>
            </div>
            <div style={{textAlign: 'right', fontSize: '80%'}}>
            v{pkg.version}
            </div>
        </div>
    );
}